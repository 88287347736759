/* Base Page */
body {
  background: #f1f4f6;
}

a:hover {
  text-decoration: underline;
}

pre {
  margin: 0px;
  padding: 0px;
  background-color: unset;
  border: 0px;
  overflow: auto;
  white-space: pre-wrap;
  font-size: 85%;
}

hr {
  margin-top: 0;
  margin-bottom: 5px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: 500;
  text-transform: uppercase;
}

.app-container {
  min-height: 100vh;
  margin: 0;
}

html.html__fixed-top-bar .app-header {
  position: fixed;
  min-width: auto;
  box-shadow: var(--bs-sm);
}

.app-main {
  flex: 1;
  display: flex;
}

.app-main .app-main__outer {
  flex: 1;
  flex-direction: column;
  display: flex;
}

.app-main .app-main__inner {
  padding: 30px 30px 0;
  flex: 1;
}

.fixed-header .app-main {
  padding-top: 60px;
}

.fixed-sidebar .app-main .app-main__outer {
  padding-left: 220px;
}

.fixed-sidebar .app-sidebar {
  position: fixed;
  height: 100vh;
}

/* Header */
.app-header {
  width: 100%;
  z-index: 10;
  background-color: var(--black-025);
  height: 60px;
  display: flex;
  align-items: center;
  z-index: 12;
}

.app-header__content {
  display: flex;
  flex: 1;
  padding: 0 1.5rem;
  height: 60px;
}

.app-header__logo {
  padding-left: 1.5rem;
}

.app-header .app-header__content .app-header-right {
  margin-left: auto;
}

.app-page-title {
  padding: 20px;
  margin: -30px -30px 30px;
  position: relative;
  background: rgba(255, 255, 255, 0.45);
}

.app-page-title .page-title-heading {
  font-size: 1.4rem;
  font-weight: 500;
}

.app-page-title .page-title-subheading {
  opacity: 0.6;
}

.dashboard-title-heading {
  opacity: 0.6;
  padding-bottom: 1rem;
  font-size: 1.1rem;
  font-weight: 400;
}

.logo-src {
  padding: 0 1.5rem;
  height: 45px;
  width: 185px;
  background: url(../images/SO_forEnterprise_45px.png);
}

.widget-content {
  padding: 1rem;
}

.widget-heading {
  font-weight: bold;
}

.widget-subheading {
  opacity: 0.5;
}

/* Sidebar */
.app-sidebar {
  width: 220px;
  display: flex;
  margin-top: -60px;
  padding-top: 60px;
  z-index: 11;
}

.app-sidebar .app-sidebar__inner {
  padding: 2rem 1rem 1rem;
}

.app-sidebar.sidebar-shadow {
  box-shadow: 7px 0 60px rgba(0, 0, 0, 0.05);
}

.s-sidebarwidget {
  position: inherit;
  border: 0px;
  box-shadow: none;
  background-color: inherit;
}

.vertical-nav-menu {
  padding: 1rem;
  list-style: none;
}

.vertical-nav-menu li a {
  display: block;
  height: 3rem;
  margin: 0.1rem 0;
  font-weight: 600;
}

.vertical-nav-menu li a i {
  padding-right: 5px;
  color: #f48024;
}

.dashboard-table {
  border-radius: 5px;
  box-shadow: var(--bs-sm);
  table-layout: auto;
}

/* Footer */
.app-footer {
  background-color: var(--black-025);
  height: 60px;
}

.app-footer .app-footer__inner {
  padding: 0 1.5rem 0 0.75rem;
  height: 60px;
  display: flex;
  align-items: center;
}

.app-footer .app-footer__inner .app-footer-right {
  margin-left: auto;
}

.app-footer .app-footer__inner .app-footer-right .footer a {
  color: #f48024;
}

/* Components */
.panel {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}

.panel-primary {
  margin-bottom: 10px;
  border-color: #f48024 !important;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}

.panel-primary > .panel-heading {
  color: #000 !important;
  background-color: #ccc !important;
  border-color: #ccc !important;
}

.panel-body {
  padding: 15px;
}

.slider {
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
}

.slider,
.slider:before {
  position: absolute;
  transition: 0.4s;
}

.slider:before {
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 4px;
  background-color: white;
}

.slider.round {
  border-radius: 25px;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #f48024;
}

input:focus + .slider {
  box-shadow: 0 0 1px #f48024;
}

input:checked + .slider:before {
  transform: translateX(18px);
}

.dashboard-thead {
  margin: 15px 0;
}

.hide {
  display: none !important;
}

.MuiTableContainer-root {
  background: rgba(255, 255, 255, 0.45) !important;
  overflow-x: unset !important;
}

.MuiTableRow-root {
  background: #fff !important;
}

.MuiAppBar-colorPrimary {
  color: #000 !important;
  background-color: #cccccc !important;
}

.MuiTabs-indicator {
  background-color: #f48024 !important;
}

.MuiInputBase-root.MuiInput-root {
  margin-top: 26px !important;
}
.upgradesel .MuiInputBase-root.MuiInput-root {
  margin-top: 9px !important;
}

.MuiInputLabel-animated {
  transition: color 0ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 0ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
}

.MuiInputLabel-formControl {
  transform: translate(0, 1.5px) scale(1) !important;
}

.MuiGrid-spacing-xs-2 > .MuiGrid-item {
  padding: 15px !important;
}

.MuiTab-root {
  padding: 6px 12px !important;
}

.MuiCollapse-root {
  padding: 20px 10px;
}

.MuiListItem-root {
    width: 100%;
    display: flex !important;
    position: relative !important;
    box-sizing: border-box;
    text-align: left;
    align-items: center !important;
    padding-top: 8px;
    padding-bottom: 8px;
    justify-content: flex-start !important;
    text-decoration: none !important;
}

.MuiMenuItem-root {
    width: auto;
    overflow: hidden;
    font-size: 1rem;
    box-sizing: border-box;
    min-height: 48px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    padding-top: 6px !important;
    white-space: nowrap;
    letter-spacing: 0.00938em;
    padding-bottom: 6px !important;
}

.MuiListItem-gutters {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

ul.s-pagination {
  list-style-type: none;
}

.s-pagination {
  margin-left: -25px !important;
}

.s-link, a {
  color: unset !important;
}

.row {
  margin-right: -5px;
  margin-left: -5px;
}

.table > tbody > tr > td {
  vertical-align: middle !important;
}

.s-btn.s-btn__primary {
  background-color: #f48024;
  margin-right: 1.5px !important;
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px
}

.s-btn.s-btn__filled.s-btn__muted {
  margin-left: 1.5px !important;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px
}

.task-modal-btn {
  width: 100%;
  color: black !important;
  font-weight: 400;
  background-color: #e5e5e5 !important;
  text-align: inherit;
}

.form-group {
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.switch {
  position: relative;
  width: 40px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 5px;
  height: 0;
}

.control-label {
  padding-left: 15px;
}

.control-label-disabled {
  padding-left: 15px;
  color: #cccccc;
}

.modify-select {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  background-color: #ccc;
  margin-bottom: 1em;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(26, 54, 126, 0.125);
  border-width: 0;
  border-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
}

.card-header {
  display: flex;
  padding: 0.75rem 1.25rem;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item {
  padding: 0.185rem 3.5rem;
  background-color: #eee;
  border: 0px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.s-card {
  padding: 0;
  border-radius: 0;
}

.input-group {
  display: table;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e5e5e5;
}

.accordion .s-card {
  background-color: #e5e5e5;
}

.s-card.active .button {
  background-color: #e5e5e5;
}

.log_wrapper {
  height: 0;
  overflow: hidden;
}

.log_wrapper.open {
  height: auto;
}
